import React, {useContext, useEffect, useState, Suspense, useMemo} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../../context';

import { H2 } from '../../theme/typography';
import { Article } from './styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as SparklesOuline} from '../../../assets/svgs/sparkles-outline.svg';


const GeneratedResultCard = (props) => {
  const { 
    userContext,
    feedbackContext, 
    setFeedbackContext,
    searchContext,
  } = useContext(
    AppContext
  );
  const isSmallScreen = useIsSmallScreen();
  const [privateToken,setPrivateToken]= useState(''); 
  const [requestExpiryToken,setRequestExpiryToken] = useState('');
  const [hasOnlinePdfUrl,setHasOnlinePdfUrl] = useState(false);
  const [urlPdf,setUrlPdf] = useState('');
  const [favourites, setFavourites] = useState(undefined);
  const [url,setUrl] = useState('');
  const [iframeAllowed, setIframeAllowed] = useState(false);
  
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [showPDFModalFlag, setShowPDFModalFlag] = useState(false);

  var onlineViewUrlParts = [];
  var purifiedHtml = "loading....";
  var previewUrl = "www.google.com/news";
  var onlineViewUrlParts = "www.google.com/news".split("/");

  onlineViewUrlParts = onlineViewUrlParts.filter(part => {
    return part !== '';
  });
  onlineViewUrlParts[1] = onlineViewUrlParts[0] + "//" + onlineViewUrlParts[1];
  onlineViewUrlParts.shift();
  var webOnlineViewUrl = onlineViewUrlParts.join(" › ");
  if (webOnlineViewUrl.length > 56)
    webOnlineViewUrl = webOnlineViewUrl.substring(0,53) + "...";




  const formattedDate = (d) => {
    const date = new Date(d);
    const today = new Date();
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate())
      return 'Today';

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.getFullYear() === date.getFullYear() && yesterday.getMonth() === date.getMonth() && yesterday.getDate() === date.getDate())
      return 'Yesterday';

    const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    return date.getDate() + ' ' + monthAbbr[date.getMonth()] + ' ' + date.getFullYear();
  };

  // When clicking on "Visit website" or card title, we want this to be logged as an interaction
  // and we want to get private token if available to load prvt link 
  // TODO: /log-dialog-interaction

  // Important note: First result is coded inline (made use of the map method)
  const handleCardClick = (e, result, i) => {
    let cardBody = e.currentTarget.querySelector('.card_body');
    if (!cardBody.classList.contains('expanded')) {
      result.expanded = 'expanded';
      setFeedbackContext({
        ...feedbackContext,
        chunk_id: result.chunk_id,
        chunk_title:result.chunk_title,
        online_view_url: result.online_view_url,
        requested_obj: result.requested_obj,
        chunk_text: result.text,
        doc_publisher: result.doc_publisher,
        rank: i,
        value: 1,
        feedbackType: 'interact',
        triggerRequest: true,
      });
    } else{
      if (isSmallScreen){
        result.expanded = undefined;
        setFeedbackContext({
          ...feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title:result.chunk_title,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          chunk_text: result.text,
          doc_publisher: result.doc_publisher,
          rank: i,
          value: 1,
          feedbackType: 'interact',
          triggerRequest: false,
        });
      }
    }
  };

  
  const handleUnexpanded = (e, result, i) => {
    result.expanded = undefined;
    result.noteExpanded = false;
    setFeedbackContext({
      ...feedbackContext,
      chunk_id: result.chunk_id,
      chunk_title:result.chunk_title,
      online_view_url: result.online_view_url,
      chunk_text:  result.text,
      doc_publisher: result.doc_publisher,
      rank: i,
      value: 1,
      feedbackType: 'interact',
      triggerRequest: false,
    });
  };

  
  useEffect (() => {
    if (requestExpiryToken !== '' && url !== '' )
    {
      //add axios and add-log and redirrect to link
      // const config = {
      //   method: 'post',
      //   url: `${window.BASE_URL_USER}get-expiry-token`,
      //   withCredentials : true,
      //   headers: {'Content-Type': 'application/json' },
      //   data: JSON.stringify({'requested': requestExpiryToken}),
      // };
  
      // axios(config)
      // .then(function(res){
        if (userContext['search_params']){
          setPrivateToken(userContext['search_params']);
          let url_token = '';
          if(hasOnlinePdfUrl){
            let s =  urlPdf.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
            if (s!== urlPdf){
              url_token = s; 
            }
          }
          else{
            if(url.includes('__PUBLIC_TOKEN__') &&  userContext['search_params']  ){
              url_token = url.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
            }
          }
          window.open(url_token,'_blank','noreferrer');

        }
        else{
          // if 400 previours url
            console.log('not found');
            window.open(urlPdf,'_blank','noreferrer');
        }
      setRequestExpiryToken('');
    }
  },[requestExpiryToken]);



  return (
    <Article className={('card_chat_on_search_container')}>
      <div className={"card " + (!props.isSearchResult && 'card_chat ') + ( 'card_chat_on_search')} >
        <div className="card_top">
          {props.isSearchResult  && !isSmallScreen &&  (
            <span 
              className={`card_expandIcon ` + !true}>
              <FaChevronUp />
            </span>
          )}
          {props.isSearchResult &&false &&  !isSmallScreen&& (
            <span
              className={`card_expandIcon ` + true}>
              <FaChevronDown />
            </span>
          )}
          <div className='title-div'>

            <SparklesOuline width={'32px'} color={"#393956"}></SparklesOuline >
            
            {/* <SparklesOuline></SparklesOuline> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
    <path d="M259.92 262.91L216.4 149.77a9 9 0 00-16.8 0l-43.52 113.14a9 9 0 01-5.17 5.17L37.77 311.6a9 9 0 000 16.8l113.14 43.52a9 9 0 015.17 5.17l43.52 113.14a9 9 0 0016.8 0l43.52-113.14a9 9 0 015.17-5.17l113.14-43.52a9 9 0 000-16.8l-113.14-43.52a9 9 0 01-5.17-5.17zM108 68L88 16 68 68 16 88l52 20 20 52 20-52 52-20-52-20zM426.67 117.33L400 48l-26.67 69.33L304 144l69.33 26.67L400 240l26.67-69.33L496 144l-69.33-26.67z"
     fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg> */}
            {/* <animatedSparks speed={5.0} backgroundColor="#e3e0f7" foregroundColor="#bbafed"></animatedSparks> */}
            <H2 h2 regular>
               Generating answer ...
            </H2> 
          </div>
          <div className='card_body'>
            <Skeleton count={'4'}  baseColor="#e3e0f7"  highlightColor='#bbafed' ></Skeleton>
          </div>
        </div>
      </div>
    </Article>  
    );
        

};

export default GeneratedResultCard;
