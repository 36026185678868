import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Container, Row, Col,Modal } from 'react-bootstrap';
import { AppContext } from '../../context';

import Header from '../../components/header';
import { P,H2,StyledLink} from '../../components/theme/typography';
import ResultCards from '../../components/result-cards';
import StyledModal from '../../components/modal/styles';
import FiltersMobile from '../../components/filters/filters-mobile';
import SideFiltersCard from '../../components/side-filters';
import SideFiltersCardMobile from '../../components/side-filters-Mobile';
import Hr from '../../components/hr';
import Footer from '../../components/footer';
import BookmarkBanner from '../../components/bookmark-banner';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import {getDefaultsFromContext,getAllSources, getDefaultSourcesFromContext, isValidSourceForAsk,getUserFilters } from '../../functions/manageSessions';//TODO chanbfe get function to user UserContextFilters
import SideFeedbackCard from '../../components/side-feedback-card';
import ModalMandantoryQuestion from '../../components/modal-madatory-question'; 
import ModalLoginToSearch from '../../components/modal-login-to-search';
import DrugNameButtons from '../../components/drug-Name-buttons';
import ModalMedicalQuery from '../../components/modal-medical-query';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';
import OutsideFilerContainer from '../../components/result-cards/outside-filter-container';
import SuggestedQuestionContainer from '../../components/result-cards/suggested-question-container';

const SearchResultsPage = ({ history, location }) => {
  const {
    fetchedResults,
    setMobileContext,
    mobileContext,
    searchContext,
    setSearchContext,
    tooManyRequestsError,
    userContextFilters,
    setRequestUser,
    userContext,
    setRequestFilters,
    requestTrackerContext,
    setRequestTrackerContext,
    userStats,
  } = useContext(AppContext);

  const isSmallScreen = useIsSmallScreen();
  const [displayMandatoryQuestionModal,setDisplayMandatoryQuestionModal] = useState(false);
  const [errorParam2, setErrorParam2] = useState(undefined);
  const [displayTooManyRequestsModal,setDisplayTooManyRequestsModal] = useState(false);
  const [displayInvalidSource,setDisplayInvalidSource] = useState(false);
  const [displayInvalidSourceModal,setDisplayInvalidSourceModal] = useState(false);
  const [displayLoginModal,setDisplayLoginModal] = useState(false);
  const [displayQueryWarningModal,setDisplayQueryWarningModal] = useState(false);
  const [waitingForSources,setWaitingForSources] = useState(false);
  const [searchParameter,setSearchParameter] = useState(false);
  const [searchSources,setSearchSources] = useState(undefined);
  const [delaySearch,setDelaySearch] = useState(false);// only use when userContext['anon'] === true
  const [delaySearchParams,  setDelaySearchParams  ] = useState({});// only use when userContext['anon'] === true

  

  // returns srcs that match the user srcs base on name
  // input: an array of string representing the name of source
  // out:  array of src objct that are found with the user srcs
  const getValidSourcesFromNames = (names)=>{
    let validSrcs= [] ;
    let tempSrc = {
      'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
      'name':'Web',
      'url': "https://www.google.co.uk",
      'id': 0, 
    };
    names.forEach(element => {
      //TO DO replace hard code    
      if (element !== 'Web'){
        if(element === 'Crohn’s & Colitis UK'){
          element= 'Crohn’s &amp; Colitis UK';
        }
        tempSrc = getSourceFromName(element);
      }
            //TO DO replace hard code    
      if ( tempSrc && (isUrlValidForAsk(tempSrc['encrypted_url']) || element ==='Web')  ){
          validSrcs.push(tempSrc);
      }
    });
    return validSrcs;
  };
  
  const getSourceFromName = useCallback( (name)=>{
    let src = undefined;
    let all_valid_sources= [];
    if(typeof userContextFilters !== 'undefined' ){
      all_valid_sources = userContextFilters['sources']; 
    }
    all_valid_sources.forEach(element => {
      if( name === element['name'] ){
        src = element;
      }
    });
    return src; 
  },[userContextFilters]);

  // returns srcs that match the user srcs  
  // input array of string representing the encrypted url
  // out array of src objct that are found with the user srcs
  const updateInvalidSources = (names)=>{
    let invalidSrcs= [] ;
    names.forEach(sourceName => {
      let tempSrc = getSourceFromName(sourceName);

      if ( tempSrc && tempSrc['encrypted_url'] ){
        if ( !isUrlValidForAsk(tempSrc['encrypted_url']) ){
          invalidSrcs.push(sourceName);
        }
      }
      else{
        invalidSrcs.push(sourceName);
      }
    });
    if (invalidSrcs && invalidSrcs.length >0 ){
      setDisplayInvalidSource(true);
    }
  };

  const handleClickAnonymous = ()=>{
    window.removeEventListener('click', handleClickAnonymous);
    if((typeof userContext === 'undefined' || userContext['is_anonymous'] )&& !displayLoginModal ){
      setDisplayLoginModal(true);
    }
  };

  // checks if a url or an encrypted url has a source in userContextFilters
  // input: an string can be an encrypted_url or url but can not be the name of an encrypted source
  // output: boolean
  const isUrlValidForAsk = useCallback((url) => {
    let is_valid_source = false;
    let all_valid_sources = userContextFilters['sources']; 
    all_valid_sources.forEach(element => {
      if( url === element['encrypted_url'] ){
        is_valid_source = true;
      }
    });
    return is_valid_source; 
      },
    [userContextFilters]);
  


  useEffect (()=> {
    if (displayInvalidSource){
      setDisplayInvalidSourceModal(true);
    };
  },[fetchedResults]);

  useEffect (()=> {
    // if we have result and a anonymous user we add an event to activate the modal on next click
    if(typeof userStats !== "undefined" && typeof userStats['num_searches'] !== undefined && userStats['num_searches']> 0  && userStats['num_searches']%3===0 &&(typeof userContext === 'undefined'|| userContext['is_anonymous']) ){
      window.addEventListener('click', handleClickAnonymous);
    }
    return()=> {
      window.removeEventListener('click', handleClickAnonymous);
    };
    
  },[userStats,userContext]);

  useEffect(() => {
    if(userContext['ask_for_details']  && !userContext['is_anonymous'] && userContext['org-user-type']!== "magic"  ){
      history.push({
        pathname: '/signup',
      });
    }
    setDisplayTooManyRequestsModal(false);
    if (history.action === 'POP'|| history.action ==='REPLACE') {
      // if (location.search === '')
      // Either page initial/re load, or through browser's forward/back buttons.
      // This eventually becomes a PUSH because context sends user back to this page with history.push
      if (location.search && location.search.substr(0, 3) === '?q=') {
        let dirtyParameter = location.search.slice(3);
        let dirtyParameterQuery = dirtyParameter.split('&sources=')[0];            
        let dirtyParameterSources = dirtyParameter.split('&sources=')[1];
        let validSrcs ; 

        if(typeof userContextFilters === undefined || requestTrackerContext['get-filters']
          || (userContext && Object.hasOwn(userContext,"is_anonymous"))
        ){
          setWaitingForSources(true);
        }
        try {
          if (dirtyParameterSources && dirtyParameterSources.length >0){
            validSrcs =decodeURI(dirtyParameterSources).split(',');
            setSearchSources(validSrcs);
          }
          const cleanParameter = decodeURI(dirtyParameterQuery);
          if (cleanParameter  && typeof userContextFilters !== undefined) {   
            if(typeof cleanParameter === 'string'){
              setSearchParameter(cleanParameter);
            }
            let allSources;
            // TO DO fix name to default sources
            // let allSources = getDefaultSourcesFromContext(userContextFilters);
            if( typeof userContextFilters !== "undefined" ){
              if(userContextFilters.hasOwnProperty('sources') ){
                allSources = userContextFilters['sources'];
              }
              
            }
            // TO DO check since number of filters  maybe smaller than default sources
              if ( (allSources && allSources.length <=0  || typeof allSources === 'undefined') || 
                  // if userContext["is_anonymous"] should not have access to this page 
                 (userContext && Object.hasOwn(userContext,"is_anonymous")) ){ // if we are waiting for filters we should wait
                setWaitingForSources(true);
                setRequestFilters(true);
              }
              else{
                //get valid sources 
                // setMobileContext({ ...mobileContext, clear: true });
                //check if we need to add sources...
                if (dirtyParameterSources && dirtyParameterSources.length >0 
                    && validSrcs && validSrcs.length >0 )
                {
                  let validSrcsForSearch = getValidSourcesFromNames(validSrcs); // TODO: change name to other id
                   if (validSrcsForSearch.length === validSrcs.length && validSrcsForSearch.length!==0 )
                  {
                    // search context will only trigger search if cleanparameter is different searchContesxt.q
                    // if coming from link q='' so search
                    setSearchContext(currentVal => 
                                    {return {...currentVal,
                                            facets: validSrcsForSearch,
                                            q: cleanParameter,
                                            };});
                    setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                  }
                  else{
                    // if ( validSrcsForSearch.length !== validSrcs.length)
                    // either there is an invalid sources or filters have not been update
                    // assumption: if user is anon we know we are waiting for user and filters
                    if(userContext['is_anonymous']  ){
                      //skip search until userCOntext is not anon
                      setDelaySearchParams({"facets":validSrcs,"q":cleanParameter});
                      setDelaySearch(true);

                    }
                    else{
                      if (validSrcsForSearch.length>0) {
                        setSearchContext(currentVal => 
                          {return {...currentVal,
                                  facets: validSrcsForSearch,
                                  q: cleanParameter,
                                  };});
                          setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                          updateInvalidSources(validSrcs);
                      }
                      else{
                        if( searchContext.q !==  cleanParameter){
                          setSearchContext(currentVal => 
                            {return {...currentVal,
                                    q: cleanParameter,
                                    };});
                          setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                          updateInvalidSources(validSrcs);
                        }
                      }
                      updateInvalidSources(validSrcs);
                    }
                  }
                }else {
                  if (cleanParameter !== searchContext.q) {
                    setSearchContext(currentVal => 
                      {return {...currentVal,
                              q: cleanParameter,
                              };});
                    setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                  }
                }
              }
          } else {
            // Send user to homepage if no search param is specified
            return history.push('/');
          }
        } catch (e) {
          // URI malformed, send user to 404
          console.log("malformed uri", e);
          return history.push('/404');
        }
      } 
      else {
        // Other error cases, send user to 404 or to an Alias search
        return history.push('/404');
      }
    } else if (history.action === 'PUSH') {
      // With the help of this state, question in the searchbar remains there after search.
      if(typeof tooManyRequestsError === 'string'){
        setDisplayTooManyRequestsModal(true);
      }
      // History.push method is called from context
      return;
    }
    // eslint-disable-next-line
  }, [location, history]);

  useEffect(()=>{
    if( userContextFilters && userContext && userContext['is_anonymous']!== true && delaySearch && delaySearchParams){
      // initiate delaySearch
      let validSrcsForSearch = getValidSourcesFromNames(delaySearchParams['facets']); // TODO: change name to other id
      setSearchContext(currentVal => 
        {return {...currentVal,
                facets: validSrcsForSearch,
                q: delaySearchParams['q'],
                };});
        setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
        updateInvalidSources(delaySearchParams['facets']);
        setDelaySearch(false); 
    }
  },[userContextFilters,userContext] )

  useEffect (() => {
    if(waitingForSources && userContextFilters && Object.keys(userContextFilters).length > 0){ /// && userContext
      //get the src if available ....
      let default_filters = getDefaultsFromContext(userContextFilters);
      if (searchSources && searchSources.length > 0 ){
        let validSources = getValidSourcesFromNames(searchSources) ;  
        if (validSources && searchSources && validSources.length !== searchSources.length){
          updateInvalidSources(searchSources);
        }
        if (validSources && validSources.length>0){
          default_filters = validSources;
        }
      }
      setWaitingForSources(false);
      if( typeof searchParameter === 'string'){
        setSearchContext(currentVal=> 
          {return {
          ...currentVal,
          facets: default_filters, 
          q: searchParameter,
          }
        });
      }
      else{
        setSearchContext(currentVal=> 
          {return {
          ...currentVal,
          facets: default_filters, 
          }
        });
      }
    }
  },[waitingForSources,userContextFilters]);


  useEffect(() => {
      if(searchContext  && searchContext.facets){
          setMobileContext({
              ...mobileContext,
              facetEntries: searchContext.facets,
          });
      }
  }, [searchContext.facets]);

  useEffect(() => {
    if(typeof fetchedResults !== 'undefined' && typeof searchContext !== 'undefined' && Object.hasOwn(searchContext,'q')  
      && typeof searchContext['q'] == 'string' && searchContext['q'].length >225){
        setDisplayQueryWarningModal(true);
    }
}, [fetchedResults]);

  useEffect(() => {
    // setDisplayMandatoryQuestionModal(true); 
    if(userContext['additional_questions'] && userContext['additional_questions'][0] && Object.values(userContext['additional_questions'][0]).length ===1 ){
      if(Object.values(userContext['additional_questions'][0])[0] === false){
        //display modal with question
        setDisplayMandatoryQuestionModal(true); 
      }
      else{
        // setDisplayMandatoryQuestionModal(true); 
        setDisplayMandatoryQuestionModal(false); 
      }
    }
    else{
      setDisplayMandatoryQuestionModal(false); 

    }  // setIsLoggedIn(false);
  },[userContext['additional_questions']]);

  useEffect(() => {

    if(userContext['ask_for_details'] && !userContext['is_anonymous']  && userContext['org-user-type'] !== "magic" ){
      history.push('/signup');
    }
    // setIsLoggedIn(false);
  },[userContext]);

  const hideModalTooManyRequests =  ()=> {
    setDisplayTooManyRequestsModal(false);
  }; 

  const hideModalInvalidSource =  ()=> {
    setDisplayInvalidSourceModal(false);
    setDisplayInvalidSource(false);
  };  

  const callModifyUserMandatoryQuestion = (boolean_val) => {
    if ( boolean_val === true)
    {
      setErrorParam2(false);
      let temp = userContext['additional_questions'][0];//this is an dict
      for (const property in temp) {
        temp[property]= true;
      }
      let objectToSend  = {'additional_questions': temp};
      
      setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}/modify-user-fields`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(objectToSend),
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
            setRequestUser(true);
            setDisplayMandatoryQuestionModal(false);
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          setErrorParam2('Error Please try again');
          console.log('Error: ', error);
        })
    }
    else{
        setErrorParam2('It is required this account.');
        //set errort msg
    }
  };

  useEffect(() => {
    if( userContext && !userContext['is_anonymous']){
      window.gtag('event', 'conversion', {'send_to': 'AW-11412832652/tM4nCMOt-PYYEIyDiMIq'});
    }
  }, []);

  return (
    <>
    {/* neet to change to hook userHasSources to hook  */}
    <StyledModal
      show={displayTooManyRequestsModal}
      onHide={() => hideModalTooManyRequests()}
      centered={true}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {'Too Many Requests '}
        </H2>
        <div >
          <FaTimes
            className="modal-header-closeIcon"
            style={{color:'#212452', height:'1em', width:'1em'}}
            onClick={() => hideModalTooManyRequests()}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <P p1 regular textoffblack >
          {tooManyRequestsError + '.'}
        </P>
      </Modal.Body>
      <Modal.Footer style={{padding:'10px 14px', justifyContent:'center'} }>
        <a href="mailto:hello@medwise.ai?subject=feedback too many requests"  
            onClick={()=> hideModalTooManyRequests()}>Contact us</a>
      </Modal.Footer>
    </StyledModal>

    <StyledModal
      show={displayInvalidSourceModal}
      onHide={() => hideModalInvalidSource()}
      centered={true}
    >
      <Modal.Header>
       { userContext['is_anonymous'] ?
       (<>
       <H2 h2 semibold textoffblack> This search was performed with your available sources. Please
       <StyledLink textlink="true" to="/login">
        &nbsp; Log in 
        </StyledLink>
        &nbsp; to access further sources!
        </H2>
        </>) 
       : (<H2 h2 semibold textoffblack>This search was performed with your available sources. </H2>)
       }
        <div >
          <FaTimes
            className="modal-header-closeIcon"
            style={{color:'#212452', height:'1em', width:'1em'}}
            onClick={() => hideModalInvalidSource()}
          />
        </div>
      </Modal.Header>
    </StyledModal>


     <ModalLoginToSearch      
        modalFeedbackShow={displayLoginModal}
        modalFeedbackClose={()=>setDisplayLoginModal(false)}
        centered={true}
        title={'Login for advance Features'}>
    </ModalLoginToSearch>

    <ModalMedicalQuery modalFeedbackShow={displayQueryWarningModal} 
        modalFeedbackClose={()=>setDisplayQueryWarningModal(false)}
        centered={true}
        title={'Warning'}></ModalMedicalQuery>
    <Header withSearchBar={true} withAccountButtons={true} />
    {(isSmallScreen)&& <FiltersMobile  numberOfFilters={getAllSources().length}/> }
    {!displayMandatoryQuestionModal && (
    <main className='headerSearchMobile' >
      <Container fluid={"xl"}  className=' pt-4 px-0' >
      {/* <Container fluid={"xl"}  className=' pt-1 px-0'> */}
          {isSmallScreen && (
            <Row className='m-0 p-0'>
              <Col className='m-0 p-0'>
            <SideFiltersCardMobile numberOfFilters={getAllSources().length}/>
            </Col></Row>
              )}
        <OutsideFilerContainer></OutsideFilerContainer>
        {/* <SuggestedQuestionContainer></SuggestedQuestionContainer> */}

        <Row className={(isSmallScreen) ? 'mx-0 px-0': '' }
        >
        {/* <Col md={3}></Col>s
        <Col className='m-0' md={9}
        style={{ display:'flex', 'border': 'none','overflow-x': 'hidden','white-space':'nowrap','background-color':'fbfbfd'}}> 
        <DrugNameButtons></DrugNameButtons>
        </Col> */}
        {(!isSmallScreen) ? (
          <Col  md={3} className='pl-1 pr-1 pt-0 ml-0'>
                <SideFiltersCard />
                {/* <FiltersDesktop /> */}
                <Hr />
                <SideFeedbackCard />
                {/* {fetchedMoreLikeThis && <MoreLikeThisSection />} */}
          </Col>) : undefined} 
          {/* <Col md={8} sm={12} style={{padding:"0px",backgroundColor: isSmallScreen? '#f0f3f4':''    }} > */}
          <Col className={isSmallScreen? 'mx-0 px-0': ''} md={isSmallScreen ? 12:9} sm={12} style={{backgroundColor: isSmallScreen ? '#f0f3f4':''    }} >
            <ResultCards />
            {(isSmallScreen )||
              (fetchedResults &&fetchedResults.length !== 0 && (
                <Hr style={{ margin: '40px 0 24px 0'}} />
              ))}
            {/* <CantFindFeedback /> */}
          </Col>
        </Row>
      </Container>
    </main>)}
      <Footer mobileResultBackground={(isSmallScreen)} />
      { (displayMandatoryQuestionModal && userContext['additional_questions'] && userContext['additional_questions'].length >0 )&& (
        <ModalMandantoryQuestion 
          orgName={userContext['org_name']}
          question={Object.keys(userContext['additional_questions'][0])[0]}
          modalFeedbackShow={displayMandatoryQuestionModal}
          // modalFeedbackShow={true}
          modalFeedbackClose={ setDisplayMandatoryQuestionModal } 
          modalFeedbackData={callModifyUserMandatoryQuestion}
          errorParam= {errorParam2}
          setErrorParam = {setErrorParam2}
          title={"Welcome to Mediwse "} 
        />
      )}
      <BookmarkBanner />
    </>
  );
};

export default SearchResultsPage;
